import React from "react";
import Icon from "../icon";

interface PreloaderProps {}

const Preloader: React.FC<PreloaderProps> = () => {
  return (
    <div className="preloader">
      <div className="preloader__grey">
        <Icon name={process.env.WEBSITE === "Sportreizen" ? "preloader-grey" : "preloader-grey-voetbalreizen"}></Icon>
      </div>
      <div className="preloader__color">
        <Icon name={process.env.WEBSITE === "Sportreizen" ? "preloader-color" : "preloader-color-voetbalreizen"}></Icon>
      </div>
    </div>
  );
};

export default Preloader;
