import React, { useContext, useEffect, useRef, useState } from "react";
import translate from "../../utils/translate";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";

interface CollapsibleProps {
  text?: string;
  isProductPage?: boolean;
}

const Collapsible: React.FC<CollapsibleProps> = ({ text, isProductPage }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);

  const [showAll, setShowAll] = useState<boolean>(false);
  const [readMoreButtonVisible, setReadMoreButtonVisible] = useState<boolean>(false);

  const handleToggleMore: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    setShowAll(!showAll);
    if (showAll) {
      executeScroll();
    }
  };

  const textBlock = useRef<HTMLDivElement>(null);
  const executeScroll = () => textBlock.current?.scrollIntoView({ behavior: "smooth", block: "center" });

  useEffect(() => {
    textBlock.current && textBlock.current.scrollHeight < textBlock.current.clientHeight && !showAll
      ? setReadMoreButtonVisible(false)
      : setReadMoreButtonVisible(true);
  }, [text, showAll]);

  return (
    <div className={`collapsible ${showAll || !isProductPage ? "collapsible--expanded" : ""}`}>
      {text && (
        <>
          <div ref={textBlock} className="collapsible__text" dangerouslySetInnerHTML={{ __html: text }} />
          {readMoreButtonVisible && (
            <button
              className="collapsible__trigger"
              type="button"
              title={!showAll ? translate(translations, language, "READ_MORE") : translate(translations, language, "READ_LESS")}
              onClick={handleToggleMore}
            >
              {isProductPage &&
                <>
                  <span className="collapsible__trigger-icon"></span>
                  <span className="collapsible__trigger-label">
                    {!showAll ? translate(translations, language, "READ_MORE") : translate(translations, language, "READ_LESS")}
                  </span>
                </>
              }
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Collapsible;
