import React, { useContext } from "react";
import { TideItemForUspFolder, TideItemForUspItem, TideItemForWebsite } from "../../../types";
import { getChildItemsWithTypename, parse } from "../../../utils/component";
import translate from "../../../utils/translate";
import Trust from "../../trust";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";

interface SummaryPanelProps {
  website?: TideItemForWebsite;
  usps?: TideItemForUspFolder;
}

const SummaryPanel: React.FC<SummaryPanelProps> = ({ website, usps }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  const tiles = getChildItemsWithTypename("TideItemForUspItem", usps) as TideItemForUspItem[];

  return (
    <div className="summary-panel">
      <div className="summary-panel__header">
        <div className="summary-panel__header-text">
          <h4 className="summary-panel__heading">
            {translate(translations, language, process.env.WEBSITE === "Voetbalreizen" ? "TITLE_VBR" : "TITLE_SPR")}
          </h4>
          <p className="summary-panel__header-subtitle">{translate(translations, language, "SUBTITLE")}</p>
        </div>
      </div>

      <div className="summary-panel__body">
        <div className="summary-panel__region">
          <div className="summary-panel__region-body">
            <div className="summary-panel__description-column">
              <div className="check-circle-list">
                <ul>{tiles && tiles.length > 0 && tiles.map((tile, i) => <li key={i}>{tile.content?.general?.text}</li>)}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Trust
        styling="summary-panel__trust"
        linkSgr={parse(website?.content?.general?.linkSgr)}
        linkThuiswinkelWaarborg={parse(website?.content?.general?.linkThuiswinkelWaarborg)}
        showStars={false}
      />
    </div>
  );
};

export default SummaryPanel;
