import React, { useContext, useEffect, useState } from "react";
import translate from "../../utils/translate";
import translations from "./translations.json";
import Icon from "../icon";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import { graphql, useStaticQuery } from "gatsby";
import { TideItemForTag, TideItemForTeam, TideItemForWedstrijd, TideTag } from "../../types";
import { addDays, format, isValid } from "date-fns";
import { first, isEmpty, orderBy, uniqBy } from "lodash";
import FilterContext, { FilterValue, FilterContextType, initialState, FilterTransportItem } from "../../contexts/filter-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { findGameById } from "../../utils/component";

interface FilterPanelProps {
  page?: string;
  teamName?: string;
  handleScroll: () => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({ page, teamName, handleScroll }) => {
  const { language, setFiltersActive } = useContext<GlobalContextType>(GlobalContext);
  const { filters, filteredPackages, setFilters } = useContext<FilterContextType>(FilterContext);
  const { games, teams } = useContext<ComponentContextType>(ComponentContext);
  const [competitions, setCompetitions] = useState<FilterValue[]>([]);
  const [clubs, setClubs] = useState<FilterValue[]>([]);
  const [gameTags, setGameTags] = useState<FilterValue[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [transports, setTransports] = useState<FilterTransportItem[]>(filters.transports);

  const tagQuery = useStaticQuery(graphql`
    query TagQuery {
      allTideTag {
        nodes {
          tideId
          id
          name
          tagGroup {
            name
          }
          localizedNames {
            languageCode
            value
          }
        }
      }
      allTideItemForTag {
        nodes {
          id
          name
        }
      }
    }
  `) as any;

  var tideTags = tagQuery.allTideTag.nodes as TideTag[];
  var cmsTags = tagQuery.allTideItemForTag.nodes as TideItemForTag[];

  useEffect(() => {
    if (filteredPackages && !isEmpty(filteredPackages)) {
      setCompetitions(
        orderBy(
          uniqBy(
            filteredPackages.flatMap((p) =>
              p.allotment.tagIds
                .map((at) => {
                  const tideTag = tideTags.find((tt) => tt.tideId === at);
                  if (tideTag && tideTag?.tagGroup?.name === "Competitie") {
                    let tagName = tideTag.name;
                    if (tideTag.localizedNames && tideTag.localizedNames.some((l) => l?.languageCode === language)) {
                      tagName = tideTag.localizedNames.find((l) => l?.languageCode === language)?.value ?? tagName;
                    }
                    return { name: tagName, id: tideTag.tideId.toString() } as FilterValue;
                  }
                  return { name: "", id: "0" };
                })
                .filter((c) => c.id !== "0")
            ),
            (c) => c?.id
          ),
          (c) => c.name
        )
      );

      setClubs(
        orderBy(
          uniqBy(
            filteredPackages
              .flatMap((p) => {
                const eventId = first(p.allotment.tourCode.split("|"));
                const game = findGameById(games, eventId);
                // don't show specific team on the teampage
                const filteredTeams = page === "team" ? teams.filter((t) => t.name !== teamName) : teams;
                const homeTeam = filteredTeams.find((t) => t.content?.general?.id === game?.content?.general?.homeTeamId);
                const awayTeam =
                  page !== "Wedstrijden"
                    ? filteredTeams.find((t) => t.content?.general?.id === game?.content?.general?.awayTeamId)
                    : ({} as TideItemForTeam);

                return [
                  { name: homeTeam?.name, id: homeTeam?.content?.general?.id ?? "0" } as FilterValue,
                  { name: awayTeam?.name, id: awayTeam?.content?.general?.id ?? "0" } as FilterValue,
                ];
              })
              .filter((c) => c.id !== "0"),
            (c) => c?.id
          ),
          (c) => c.name
        )
      );

      setGameTags(
        orderBy(
          uniqBy(
            filteredPackages
              .map((p) => {
                const eventId = first(p.allotment.tourCode.split("|"));
                const game = findGameById(games, eventId);
                return game as TideItemForWedstrijd;
              })
              .flatMap((g) => {
                const tagsIds = g?.content?.general?.tagsIds ?? [];
                return tagsIds.map((tagId) => {
                  const cmsTag = cmsTags.find((tt) => tt.id === tagId);
                  if (cmsTag) {
                    return { name: cmsTag.name, id: cmsTag.id } as FilterValue;
                  }
                  return { name: "", id: "0" };
                });
              })
              .filter((c) => c.id !== "0"),
            (c) => c?.id
          ),
          (c) => c.name
        )
      );

      setCountries(
        orderBy(
          uniqBy(
            filteredPackages.map((p) => p.countryName ?? ""),
            (countrie) => countrie
          ),
          (countrie) => countrie
        )
      );

      setCities(
        orderBy(
          uniqBy(
            filteredPackages.map((p) => p.locationName ?? ""),
            (location) => location
          ),
          (location) => location
        )
      );

      // Always show without_flights if a package is present
      // let transports = [];
      // if (!isEmpty(filteredPackages)) {
      //   if (isEmpty(filters.transports)) {
      //     transports.push("WITHOUT_FLIGHTS");
      //     if (filteredPackages.some((f) => f.includedServiceTypes.includes(ServiceType.flight))) {
      //       transports.push("WITH_FLIGHTS");
      //     }
      //   } else {
      //     filters.transports.map((t) => transports.push(t));
      //   }
      // }
      // setTransports(transports);
    }
  }, [filteredPackages]);

  const changeDate = (type: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type == "date" && isValid(new Date(event.target.value))) {
      switch (type) {
        case "From":
          setFilters({ ...filters, fromDate: new Date(event.target.value) });
          break;
        case "To":
          setFilters({ ...filters, toDate: new Date(event.target.value) });
        default:
          break;
      }
    }
  };

  const adjustFilters = (type: string, value: FilterValue | FilterTransportItem | string, add: boolean) => {
    handleScroll();
    switch (type) {
      case "Competition":
        const competitionFilterValue = value as FilterValue;
        setFilters({
          ...filters,
          competitions: add
            ? [...filters.competitions, competitionFilterValue]
            : filters.competitions.filter((c) => c.id !== competitionFilterValue.id),
        });
        break;
      case "Club":
        const clubFilterValue = value as FilterValue;
        setFilters({
          ...filters,
          clubs: add ? [...filters.clubs, clubFilterValue] : filters.clubs.filter((c) => c.id !== clubFilterValue.id),
        });
        break;
      case "Country":
        setFilters({
          ...filters,
          countries: add ? [...filters.countries, value as string] : filters.countries.filter((c) => c !== value),
        });
        break;
      case "City":
        setFilters({ ...filters, cities: add ? [...filters.cities, value as string] : filters.cities.filter((c) => c !== value) });
        break;
      case "Transport":
        const transportValue = value as FilterTransportItem;
        const newTransports = filters.transports.map((t) => {
          if (t.name == transportValue.name) {
            t.selected = add;
          }
          return t;
        });
        setFilters({
          ...filters,
          transports: newTransports,
        });
        break;
      case "Game Tag":
        const gameTagFilterValue = value as FilterValue;
        setFilters({
          ...filters,
          gameTags: add ? [...filters.gameTags, gameTagFilterValue] : filters.gameTags.filter((t) => t.id !== gameTagFilterValue.id),
        });
        break;
      default:
        break;
    }
  };

  const changeCheckbox = (type: string, value: FilterValue | FilterTransportItem | string, event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    adjustFilters(type, value, event.target.checked);
  };

  const deleteFilter = (type: string, value: FilterValue | FilterTransportItem | string) => {
    adjustFilters(type, value, false);
  };

  return (
    <div className="filter-panel">
      <button
        type="button"
        className="filter-panel__close-button"
        title={translate(translations, language, "CLOSE_BUTTON")}
        onClick={() => setFiltersActive(false)}
      ></button>
      <div className="filter-panel__backdrop" onClick={() => setFiltersActive(false)}></div>
      <div className="filter-panel__shelf">
        <div className="filter-panel__header">
          <h4 className="filter-panel__heading">{translate(translations, language, "FILTER_TITLE")}</h4>
          <button type="button" className="filter-panel__clear-all" title="Wis filters" onClick={() => setFilters(initialState.filters)}>
            {translate(translations, language, "DELETE_FILTERS")}
          </button>
        </div>
        <div className="filter-panel__body">
          <div className="filter-panel__region">
            <div className="filter-panel__active-filters">
              {filters.competitions.map((competition) => (
                <div className="filter-panel__active-filter" key={competition.id}>
                  {competition.name}
                  <button
                    type="button"
                    className="filter-panel__clear-icon"
                    title={translate(translations, language, "CLEAR_FILTER")}
                    onClick={() => deleteFilter("Competition", competition)}
                  ></button>
                </div>
              ))}
              {filters.clubs.map((club) => (
                <div className="filter-panel__active-filter" key={club.id}>
                  {club.name}
                  <button
                    type="button"
                    className="filter-panel__clear-icon"
                    title={translate(translations, language, "CLEAR_FILTER")}
                    onClick={() => deleteFilter("Club", club)}
                  ></button>
                </div>
              ))}
              {filters.countries.map((country) => (
                <div className="filter-panel__active-filter" key={country}>
                  {country}
                  <button
                    type="button"
                    className="filter-panel__clear-icon"
                    title={translate(translations, language, "CLEAR_FILTER")}
                    onClick={() => deleteFilter("Country", country)}
                  ></button>
                </div>
              ))}
              {filters.cities.map((city) => (
                <div className="filter-panel__active-filter" key={city}>
                  {city}
                  <button
                    type="button"
                    className="filter-panel__clear-icon"
                    title={translate(translations, language, "CLEAR_FILTER")}
                    onClick={() => deleteFilter("City", city)}
                  ></button>
                </div>
              ))}
              {filters.gameTags.map((gameTag) => (
                <div className="filter-panel__active-filter" key={gameTag.id}>
                  {gameTag.name}
                  <button
                    type="button"
                    className="filter-panel__clear-icon"
                    title={translate(translations, language, "CLEAR_FILTER")}
                    onClick={() => deleteFilter("Game Tag", gameTag)}
                  ></button>
                </div>
              ))}
              {filters.transports
                .filter((t) => t.selected)
                .map((transport) => (
                  <div className="filter-panel__active-filter" key={transport.name}>
                    {translate(translations, language, transport.name)}
                    <button
                      type="button"
                      className="filter-panel__clear-icon"
                      title={translate(translations, language, "CLEAR_FILTER")}
                      onClick={() => deleteFilter("Transport", transport)}
                    ></button>
                  </div>
                ))}
            </div>
          </div>

          <div className="filter-panel__region">
            <h5 className="filter-panel__region-heading">
              <Icon name="calendar" />
              {translate(translations, language, "FROM")}
            </h5>
            <div className="qsm">
              <form className="form">
                <div className="form__row">
                  <div className="form__group">
                    <div className="qsm__trigger">
                      <input
                        type="date"
                        className="form__input"
                        placeholder={translate(translations, language, "FROM")}
                        value={filters.fromDate && isValid(filters.fromDate) ? format(filters.fromDate, "yyyy-MM-dd") : ""}
                        min={format(new Date(), "yyyy-MM-dd")}
                        onChange={(event) => changeDate("From", event)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="filter-panel__region">
            <h5 className="filter-panel__region-heading">
              <Icon name="calendar" />
              {translate(translations, language, "TO")}
            </h5>
            <div className="qsm">
              <form className="form">
                <div className="form__row">
                  <div className="form__group">
                    <div className="qsm__trigger">
                      <input
                        type="date"
                        className="form__input"
                        placeholder={translate(translations, language, "TO")}
                        value={filters.toDate && isValid(filters.toDate) ? format(filters.toDate, "yyyy-MM-dd") : ""}
                        min={format(new Date(addDays(filters.fromDate ?? new Date(), 1)), "yyyy-MM-dd")}
                        onChange={(event) => changeDate("To", event)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {competitions && competitions.length > 0 && page !== "tournaments" && (
            <div className="filter-panel__region">
              <h5 className="filter-panel__region-heading">{translate(translations, language, "COMPETITIONS")}</h5>
              <div className="checkbox-list">
                {competitions.map((competition, i) => (
                  <label className="checkbox" key={i}>
                    <input
                      key={i}
                      type="checkbox"
                      className="checkbox__input"
                      id={competition.id.toString()}
                      checked={filters.competitions.some((c) => c.id === competition.id)}
                      onChange={(event) => changeCheckbox("Competition", competition, event)}
                    />
                    <span className="checkbox__text">{competition.name}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {clubs && clubs.length > 0 && (
            <div className="filter-panel__region">
              <h5 className="filter-panel__region-heading">
                {page === "team" ? translate(translations, language, "OPPONENTS") : translate(translations, language, "CLUBS")}
              </h5>
              <div className="checkbox-list">
                {clubs.map((club, i) => (
                  <label className="checkbox" key={i}>
                    <input
                      key={i}
                      type="checkbox"
                      className="checkbox__input"
                      id={club.id.toString()}
                      checked={filters.clubs.some((c) => c.id === club.id)}
                      onChange={(event) => changeCheckbox("Club", club, event)}
                    />
                    <span className="checkbox__text">{club.name}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {countries && countries.length > 0 && page !== "countries" && page !== "team" && (
            <div className="filter-panel__region">
              <h5 className="filter-panel__region-heading">{translate(translations, language, "COUNTRIES")}</h5>
              <div className="checkbox-list">
                {countries.map((country, i) => (
                  <label className="checkbox" key={i}>
                    <input
                      key={i}
                      type="checkbox"
                      className="checkbox__input"
                      id={country}
                      checked={filters.countries.some((c) => c === country)}
                      onChange={(event) => changeCheckbox("Country", country, event)}
                    />
                    <span className="checkbox__text">{country}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {cities && cities.length > 0 && page !== "team" && (
            <div className="filter-panel__region">
              <h5 className="filter-panel__region-heading">{translate(translations, language, "CITIES")}</h5>
              <div className="checkbox-list">
                {cities.map((city, i) => (
                  <label className="checkbox" key={i}>
                    <input
                      key={i}
                      type="checkbox"
                      className="checkbox__input"
                      id={city}
                      checked={filters.cities.some((c) => c === city)}
                      onChange={(event) => changeCheckbox("City", city, event)}
                    />
                    <span className="checkbox__text">{city}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {gameTags && gameTags.length > 0 && (
            <div className="filter-panel__region">
              <h5 className="filter-panel__region-heading">{translate(translations, language, "GAME_TYPES")}</h5>
              <div className="checkbox-list">
                {gameTags.map((gameTag, i) => (
                  <label className="checkbox" key={i}>
                    <input
                      key={i}
                      type="checkbox"
                      className="checkbox__input"
                      id={gameTag.id}
                      checked={filters.gameTags.some((gt) => gt.id === gameTag.id)}
                      onChange={(event) => changeCheckbox("Game Tag", gameTag, event)}
                    />
                    <span className="checkbox__text">{gameTag.name}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {transports && transports.length > 0 && (
            <div className="filter-panel__region">
              <h5 className="filter-panel__region-heading">{translate(translations, language, "TRANSPORTS")}</h5>
              <div className="checkbox-list">
                {transports.map((transport, i) => (
                  <label className="checkbox" key={i}>
                    <input
                      type="checkbox"
                      className="checkbox__input"
                      id={transport.name}
                      checked={transport.selected}
                      onChange={(event) => changeCheckbox("Transport", transport, event)}
                    />
                    <span className="checkbox__text">{translate(translations, language, transport.name)}</span>
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="filter-panel__footer">
          <button type="button" className="cta" title="Filters toepassen" onClick={() => setFiltersActive(false)}>
            Filters toepassen
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
