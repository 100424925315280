import React from "react";
import { TideItemForUspFolder, TideItemForWebsite } from "../../../types";
import Collapsible from "../../collapsible";
import SummaryPanel from "../../summary-panel/wildcard";
import { Image } from "../../../types";
import { parse } from "../../../utils/component";
import { addWidthParameter } from "../../../utils/image-utils";

interface IntroProps {
  title?: string;
  text?: string;
  website?: TideItemForWebsite;
  usps?: TideItemForUspFolder;
  image?: Image;
  extraClassName?: string;
}

const Intro: React.FC<IntroProps> = ({ title, text, website, usps, image, extraClassName }) => {
  return (
    <div className={`product-intro ${extraClassName}`}>
      <div className="product-intro__container">
        <div className="product-intro__text">
          {title && (
            <div className="heading-seperator heading-seperator--icon">
              <div className="iconheading">
                <div className="iconheading__container">
                  {image && <img src={addWidthParameter(parse(image?.url), 75)} className="iconheading__icon" alt={parse(image?.altText)} />}
                  <h1 className="iconheading__text">{title}</h1>
                </div>
              </div>
            </div>
          )}

          <Collapsible text={text} />
        </div>

        <div className="product-intro__summary-panel">
          <SummaryPanel website={website} usps={usps} />
        </div>
      </div>
    </div>
  );
};

export default Intro;
