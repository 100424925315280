import { ServiceType } from "@qite/tide-client";
import { BookingPackageItem } from "@qite/tide-client/build/types";
import { addDays } from "date-fns";
import { first, isEmpty, orderBy } from "lodash";
import { Filters } from "../contexts/filter-provider";
import { TideItemForWedstrijd } from "../types";
import { findGameById } from "./component";

const getOrderField = (sortType: string) => {
  switch (sortType) {
    case "DATE_UP":
    case "DATE_DOWN":
      return ["fromDate"]
    case "PRICE_UP":
    case "PRICE_DOWN":
      return ["price"]
    default:
      return ["fromDate"];
  }
}

const getOrderDirection = (sortType: string) => {
  switch (sortType) {
    case "DATE_UP":
    case "PRICE_UP":
      return "asc"
    case "DATE_DOWN":
    case "PRICE_DOWN":
      return "desc"
    default:
      return "asc";
  }
}

const comparePricesAsc = (a: BookingPackageItem, b: BookingPackageItem) => {
  return (a.price - a.flightPrice) - (b.price - b.flightPrice);
}

const comparePricesDesc = (a: BookingPackageItem, b: BookingPackageItem) => {
  return (b.price - b.flightPrice) - (a.price - a.flightPrice);
}

export const filterPackages = (
  packages: BookingPackageItem[],
  filters: Filters,
  sorting: string,
  games: TideItemForWedstrijd[],
): BookingPackageItem[] => {
  const filterByCompetition = (bookingPackage: BookingPackageItem) => {
    if (isEmpty(filters.competitions)) {
      return true;
    }
    return !isEmpty(bookingPackage.allotment.tagIds) && filters.competitions.some(c => bookingPackage.allotment.tagIds.map(a => a.toString()).includes(c.id));
  }

  const filterByClubs = (bookingPackage: BookingPackageItem) => {
    if (isEmpty(filters.clubs)) {
      return true;
    }

    const eventId = first(bookingPackage.allotment.tourCode.split("|"));
    const game = findGameById(games, eventId);
    return game !== undefined && (filters.clubs.some(c => c.id === game.content?.general?.homeTeamId) || filters.clubs.some(c => c.id === game.content?.general?.awayTeamId))
  }

  const filterByPeriod = (bookingPackage: BookingPackageItem) => {
    if (!filters.fromDate && !filters.toDate) {
      return true;
    }
    if (filters.fromDate && filters.toDate) {
      return new Date(bookingPackage.fromDate) > addDays(filters.fromDate, -1) && new Date(bookingPackage.fromDate) < addDays(filters.toDate, 1);
    }
    if (filters.fromDate) {
      return new Date(bookingPackage.fromDate) > addDays(filters.fromDate, -1);
    }
    if (filters.toDate) {
      return new Date(bookingPackage.fromDate) < addDays(filters.toDate, 1);
    }
    return true;
  }

  const filterByCountry = (bookingPackage: BookingPackageItem) => {
    if (isEmpty(filters.countries)) {
      return true;
    }
    return filters.countries.includes(bookingPackage.countryName ?? "");
  }

  const filterByCity = (bookingPackage: BookingPackageItem) => {
    if (isEmpty(filters.cities)) {
      return true;
    }
    return filters.cities.includes(bookingPackage.locationName ?? "");
  }

  const filterByGameTag = (bookingPackage: BookingPackageItem) => {
    if (isEmpty(filters.gameTags)) {
      return true;
    }
    const eventId = first(bookingPackage.allotment.tourCode.split("|"));
    const game = findGameById(games, eventId);
    return game !== undefined && (filters.gameTags.some(c => game.content?.general?.tagsIds && game.content?.general?.tagsIds.map(a => a?.toString()).includes(c.id)));
  }

  const filterByTransports = (bookingPackage: BookingPackageItem) => {
    if (isEmpty(filters.transports) || filters.transports.length === 2) {
      return true;
    }
    if (filters.transports.some(t => t.serviceType == ServiceType.flight)) {
      return bookingPackage.includedServiceTypes.includes(ServiceType.flight);
    }
    return !bookingPackage.includedServiceTypes.includes(ServiceType.flight);
  }

  const filteredPackages = packages.filter(
    (bookingPackage) =>
      filterByCompetition(bookingPackage) &&
      filterByClubs(bookingPackage) &&
      filterByPeriod(bookingPackage) &&
      filterByCountry(bookingPackage) &&
      filterByCity(bookingPackage) &&
      filterByGameTag(bookingPackage) &&
      filterByTransports(bookingPackage)
  );


  // special sorting for prices
  if (sorting && getOrderField(sorting).includes("price")) {
    const sortedPackages = filteredPackages.sort(getOrderDirection(sorting) === 'asc' ? comparePricesAsc : comparePricesDesc);
    return sortedPackages;
  } else {
    const sortedPackages = sorting
      ? orderBy(filteredPackages, getOrderField(sorting), getOrderDirection(sorting))
      : filteredPackages;
    return sortedPackages;
  }
}