import React, { useContext, useEffect, useRef, useState } from "react";
import PackageContext, { PackageContextType } from "../../contexts/package-provider";
import translate from "../../utils/translate";
import translations from "./translations.json";
import Icon from "../icon";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import Preloader from "../preloader";
import { CreateProductCardItems } from "../../utils/package-utils";
import ProductCard, { ProductCardItem } from "../cardgrid/cards/product-card";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import FilterPanel from "../filter-panel";
import FilterContext, { FilterContextType } from "../../contexts/filter-provider";
import { first, isEmpty } from "lodash";
import ReactPaginate from "react-paginate";
import Link from "../link";

interface FacetedsearchProps {
  page?: string;
  teamName?: string;
  itemsPerPage: number;
}

const FacetedSearch: React.FC<FacetedsearchProps> = ({ page, teamName, itemsPerPage }) => {
  const { packages, packagesLoading } = useContext<PackageContextType>(PackageContext);
  const { language, affiliate, filtersActive, setFiltersActive } = useContext<GlobalContextType>(GlobalContext);
  const { teams, tournaments, games } = useContext<ComponentContextType>(ComponentContext);
  const { setInitialPackages, sorting, setSorting, filteredPackages, filters } = useContext<FilterContextType>(FilterContext);

  const [currentItems, setCurrentItems] = useState<ProductCardItem[]>([]);
  const [cardsReady, setCardsReady] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [forcePage, setForcePage] = useState<number>();

  const [productCardItems, setProductCardItems] = useState<ProductCardItem[] | undefined>(undefined);
  const sortingOptions = ["DATE_UP", "DATE_DOWN", "PRICE_UP", "PRICE_DOWN"];

  const headingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (packages && !packagesLoading) {
      if (isEmpty(packages)) {
        setCardsReady(true);
      }
      setInitialPackages(packages.filter((p) => p !== null && p.allotment.tourCode !== null));
    }
  }, [packages, packagesLoading]);

  useEffect(() => {
    if (filteredPackages && games && !packagesLoading) {
      const cardItems =
        CreateProductCardItems(filteredPackages, translate(translations, language, "SHOW_TRIP"), true, teams, tournaments, games, affiliate) ?? [];

      setProductCardItems(cardItems);
      setItemOffset(0);
      setForcePage(0);
    }
  }, [filteredPackages, sorting, games, packagesLoading]);

  useEffect(() => {
    if (productCardItems && !packagesLoading) {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(productCardItems.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(productCardItems.length / itemsPerPage));

      setCardsReady(true);
    }
  }, [itemOffset, productCardItems, packagesLoading]);

  const changeSorting = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSorting(sortingOptions[event.target.selectedIndex]);
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setForcePage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % (productCardItems ? productCardItems.length : 0);
    setItemOffset(newOffset);
    handleScroll();
  };

  const handleScroll = () => {
    if (typeof window !== "undefined" && headingRef.current?.offsetTop) {
      window.scrollTo({
        top: headingRef.current?.offsetTop - 150,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`faceted-search ${filtersActive ? "faceted-search--active" : ""}`}>
      <div className="faceted-search__container">
        <div className="faceted-search__filters">
          <FilterPanel page={page} teamName={teamName} handleScroll={() => handleScroll()} />
        </div>

        <div className="faceted-search__header" ref={headingRef}>
          <div className="heading-seperator">
            <h2 className="faceted-search__heading">
              {packagesLoading || !cardsReady
                ? translate(translations, language, "SEARCHING_TRAVELS")
                : `${productCardItems ? productCardItems.length : 0} ${translate(translations, language, "TRAVELS_FOUND")}`}
            </h2>
          </div>

          <div className="faceted-search__filters-toggle">
            <button type="button" className="cta cta--white" onClick={() => setFiltersActive(true)}>
              <Icon name="filters" />
              Filters
            </button>
          </div>

          <div className="select-themed">
            <span className="select-themed__label">Sorteer op:</span>
            <div className="select-themed__select">
              <select onChange={(event) => changeSorting(event)} defaultValue={first(sortingOptions)}>
                {sortingOptions.map((so, i) => (
                  <option key={i}>{translate(translations, language, so.toUpperCase())}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="faceted-search__cards">
          {(packagesLoading || !cardsReady) && <Preloader></Preloader>}
          {!packagesLoading &&
            packages &&
            cardsReady &&
            currentItems.length > 0 &&
            currentItems.map((productCardItem, i) => (
              <ProductCard key={`ProductCard-${i}`} productCardItem={productCardItem} transportFilter={filters.transports} />
            ))}

          {!packagesLoading && packages?.length == 0 && currentItems.length == 0 && cardsReady && (
            <span>
              Vraag <Link path="/groepsreizen">hier</Link> jouw offerte aan &amp; we brengen je op de hoogte zodra je deze reis kan boeken.
            </span>
          )}
        </div>
        <div className="faceted-search__pager">
          <ReactPaginate
            forcePage={forcePage}
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            containerClassName="pager"
            previousClassName="pager__return"
            previousLinkClassName="pager__button"
            nextClassName="pager__forward"
            nextLinkClassName="pager__button"
            activeClassName="pager__active-page"
            pageLinkClassName="pager__anchor"
          />
        </div>
      </div>
    </div>
  );
};

export default FacetedSearch;
