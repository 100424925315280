import { BookingPackageItem } from "@qite/tide-client/build/types";
import { ServiceType } from "@qite/tide-client";
import { addYears } from "date-fns";
import { addDays } from "date-fns/esm";
import { isEmpty } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { filterPackages } from "../../utils/filter-packages";
import { ComponentContext, ComponentContextType } from "../component-provider";

export interface FilterValue {
  name: string;
  id: string;
}

export interface FilterTransportItem {
  name: string;
  selected: boolean;
  serviceType?: number;
}

export interface Filters {
  competitions: FilterValue[];
  clubs: FilterValue[];
  gameTags: FilterValue[];
  countries: string[];
  cities: string[];
  transports: FilterTransportItem[];
  fromDate: Date;
  toDate: Date;
}

interface FilterProviderProps {}

export interface FilterContextType {
  initialPackages: BookingPackageItem[];
  filters: Filters;
  sorting: string;
  filteredPackages: BookingPackageItem[] | undefined;
  setInitialPackages: (bookingPackages: BookingPackageItem[]) => void;
  setFilters: (filters: Filters) => void;
  setSorting: (sorting: string) => void;
}

export const initialState: FilterContextType = {
  initialPackages: [],
  filters: {
    competitions: [],
    clubs: [],
    gameTags: [],
    countries: [],
    cities: [],
    transports: [
      { name: "WITHOUT_FLIGHTS", selected: false, serviceType: ServiceType.event },
      { name: "WITH_FLIGHTS", selected: false, serviceType: ServiceType.flight },
    ],
    fromDate: addDays(new Date(), 3),
    toDate: addYears(addDays(new Date(), 3), 1),
  },
  sorting: "Date",
  filteredPackages: undefined,
  setInitialPackages: () => {},
  setFilters: () => {},
  setSorting: () => {},
};

const FilterContext = createContext(initialState);

export const FilterProvider: React.FC<FilterProviderProps> = (props) => {
  const [initialPackages, setInitialPackages] = useState<BookingPackageItem[]>(initialState.initialPackages);
  const [filters, setFilters] = useState<Filters>(initialState.filters);
  const [sorting, setSorting] = useState<string>(initialState.sorting);
  const [filteredPackages, setFilteredPackages] = useState<BookingPackageItem[] | undefined>(initialState.filteredPackages);

  const { games } = useContext<ComponentContextType>(ComponentContext);

  useEffect(() => {
    if (!isEmpty(initialPackages)) {
      setFilteredPackages(filterPackages(initialPackages, filters, sorting, games));
    }
  }, [filters, initialPackages, sorting]);

  return (
    <FilterContext.Provider value={{ initialPackages, filters, sorting, filteredPackages, setInitialPackages, setFilters, setSorting }}>
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterContext;
